/* eslint-disable no-console */
import { createContext, useContext, useMemo, useEffect, useState, useCallback } from "react";
import PWCore, {
  RawTransaction,
  AddressType,
  Transaction,
  EthProvider,
  PwCollector,
  EthSigner,
  OutPoint,
  CellDep,
  Builder,
  Address,
  Amount,
  Script,
  Cell,
  // AmountUnit,
} from "@lay2/pw-core";
import APIEndpoints from "APIEndpoints";
import { useResource } from "hooks";
import { useSession } from "./Session";

let pwCore = null;

const NODE_URL = `${process.env.NEXT_PUBLIC_API_URL}/node`;
// const INDEXER_URL = `${process.env.NEXT_PUBLIC_API_URL}/indexer`;
// const collector = new IndexerCollector(INDEXER_URL);

const parseTransaction = async (transaction) => {
  const hxShannonToCKB = (hexString) => Number(parseInt(hexString, 16) / 1.0e8);

  const { rawTransaction, filteredInputCells } = transaction;

  const inputsCells = filteredInputCells.map((cell) => new Cell(
    new Amount(hxShannonToCKB(cell.capacity), 8),
    new Script(
      cell.lock.codeHash,
      cell.lock.args,
      cell.lock.hashType,
    ),
    cell.type ? new Script(
      cell.type.codeHash,
      cell.type.args,
      cell.type.hashType,
    ) : undefined,
    new OutPoint(
      cell.outPoint.txHash,
      cell.outPoint.index,
    ),
    cell.data,
  ));

  const outputCells = rawTransaction.outputs.map((cell, i) => new Cell(
    new Amount(hxShannonToCKB(cell.capacity), 8),
    new Script(
      cell.lock.codeHash,
      cell.lock.args,
      cell.lock.hashType,
    ),
    cell.type ? new Script(
      cell.type.codeHash,
      cell.type.args,
      cell.type.hashType,
    ) : undefined,
    cell.outPoint ? new OutPoint(
      cell.outPoint.txHash,
      cell.outPoint.index,
    ) : undefined,
    rawTransaction.outputsData[i],
  ));

  const cellDeps = rawTransaction.cellDeps.map((cell) => new CellDep(
    cell.depType === "depGroup" ? "dep_group" : "code",
    new OutPoint(
      cell.outPoint.txHash,
      cell.outPoint.index,
    ),
  ));

  const transactionToSign = new Transaction(
    new RawTransaction(inputsCells, outputCells, cellDeps),
    [Builder.WITNESS_ARGS.Secp256k1],
  );
  transactionToSign.validate();

  return transactionToSign;
};

export const BlockchainContext = createContext({
  balance: 0,
  address: "",
  addressCKB: "",
});

export const useBlockchain = () => useContext(BlockchainContext);

export const BlockchainProvider = ({ children }) => {
  const { data: session } = useSession();

  // states
  // const [balance, setBalance] = useState(0);
  const [address, setAddress] = useState("");
  const [addressCKB, setAddressCKB] = useState("");
  const [signer, setSigner] = useState(null);

  const { data: balance } = useResource(
    address
      ? APIEndpoints.USER.GET_BALANCE
      : null,
  );

  // const getBalance = useCallback(async () => {
  //   try {
  //     // Get balance derectly from NODE and convert the balance to string in CKB
  //     const balance = await collector.getBalance(address);
  //     setBalance(balance.toString(AmountUnit.ckb));
  //   } catch (error) {
  //     //
  //   }
  // }, [address]);

  const signTransaction = useCallback(async (transaction) => {
    if (transaction) {
      if (!window.ethereum.selectedAddress) {
        throw new Error("NO_CKB_ADDRESS");
      }

      try {
        const transactionToSign = await parseTransaction(transaction);
        const txHash = await pwCore.sendTransaction(transactionToSign, signer);
        return txHash;
      } catch (error) {
        console.log("pwcore execution fail: ", error.message);
        throw new Error("CANNOT_PROCESS_TRANSACTION");
      }
    } else {
      console.log("cannot retrieve information for the current transaction");
      throw new Error("CANNOT_PROCESS_TRANSACTION");
    }
  }, [signer]);

  useEffect(() => {
    if (session) {
      (async () => {
        try {
          pwCore = await new PWCore(NODE_URL).init(
            new EthProvider(), // a built-in Provider for Ethereum env.
            new PwCollector(), // a custom Collector to retrive cells from cache server.
          );

          const metamask = session.user.authenticationMethods.find((am) => am.method === "metamask");
          const address = new Address(metamask.value, AddressType.eth);
          const signer = new EthSigner(address.addressString);
          
          setSigner(signer);
          setAddress(address);
          setAddressCKB(address.toCKBAddress());
        } catch (err) {
          console.error("PWCore: ", err.message);
        }
      })();
    }
  }, [session]);

  // useEffect(() => {
  //   getBalance();
    
  //   setInterval(() => {
  //     getBalance();
  //   }, 30000);
  // }, [address]);
 
  const data = useMemo(() => ({
    balance: balance?.ckb || 0.00,
    address,
    addressCKB,
    signTransaction,
  }), [balance, address, addressCKB, signTransaction]);

  return (
    <BlockchainContext.Provider value={data}>
      {children}
    </BlockchainContext.Provider>
  );
};

/* eslint-disable react/display-name */
export const withBlockchain = (Component) => (props) => (
  <BlockchainProvider>
    <Component {...props} />
  </BlockchainProvider>
);
