const createSeoConfig = ({
  title,
  description,
  images,
  author,
  ...rest
}) => ({
  title,
  description,
  canonical: "https://celebritysmackdown.gg/",
  author,
  openGraph: {
    url: "https://celebritysmackdown.gg/",
    title,
    description,
    images,
    site_name: "Celebrity Deatchmatch",
    author,
    twitter: {
      cardType: "summary",
      site: "@CSDGame",
      handle: "@CSDGame",
    },
  },

  ...rest,
});

export default createSeoConfig;
