import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { createContext, useContext, useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

const typhographySizes = {
  h1: { min: 48, max: 96 },
  h2: { min: 30, max: 60 },
  h3: { min: 24, max: 48 },
  h4: { min: 17, max: 37 },
  h5: { min: 12, max: 24 },
  h6: { min: 10, max: 20 },
};

const fluidTypography = (variant) => {
  // calc based on the next formula
  const { min, max } = typhographySizes[variant];
  
  return `calc(${min}px + (${max} - ${min}) * (100vw - 300px) / 1500) !important`;
};

export const light = responsiveFontSizes(
  createTheme({
    size: {
      fullWidth: "100%",
      fullHeight: "100%",
    },
    custom: {
      color: {
        white: "#FFFFFF",
        black: "#000000",
        primary: "#D90404",
        secondary: "#1D1D1D",
      },
      font: {
        paytoneOne: "Paytone One",
        poppins: "Poppins",
      },
    },
    overrides: {
      MuiGrid: {
        "spacing-xs-1": {
          width: "100%",
          margin: "auto",
        },
      },
      MuiButton: {
        label: {
          fontWeight: "bold",
          textTransform: "uppercase",
        },
      },
      MuiTypography: {
        h1: {
          fontSize: fluidTypography("h1"),
        },
        h2: {
          fontSize: fluidTypography("h2"),
        },
        h3: {
          fontSize: fluidTypography("h3"),
        },
        h4: {
          fontSize: fluidTypography("h4"),
        },
        h5: {
          fontSize: fluidTypography("h5"),
        },
        h6: {
          fontSize: fluidTypography("h6"),
        },
        root: {
          textTransform: "uppercase",
        },
        button: {
          textTransform: "uppercase",
        },
      },
    },
    props: {
      MuiTooltip: {
        arrow: true,
        style: {
          pointerEvents: "all",
        },
      },
      MuiButton: {
        disableFocusRipple: true,
        // style: {
        //   fontWeight: "bold",
        //   textTransform: "uppercase",
        // },
      },
      MuiDivider: {
        style: {
          marginTop: 16,
          marginBottom: 16,
        },
      },
    },
    typography: {
      allVariants: {
        fontFamily: "Paytone One",
      },
      button: {
        textTransform: "capitalize",
        fontFamily: "Poppins",
      },
    },
    shape: {
      borderRadius: 0,
    },
    palette: {
      type: "light",
      primary: {
        main: "#D90404",
        contrastText: "#FFFFFF",
      },
      secondary: {
        light: "#1D1D1D",
        main: "#0D0D0D",
        dark: "#0D0D0D",
        contrastText: "#FFFFFF",
      },
      text: {
        primary: "#000000de",
        secondary: "#0000008a",
        disabled: "#00000061",
        hint: "#00000061",
      },
      background: {
        paper: "#FFFFFF",
        default: "#FFFFFF",
      },
      common: {
        black: "#000000",
        white: "#FFFFFF",
      },
    },
  }),
);

export const dark = responsiveFontSizes(
  createTheme({
    overrides: {
      MuiChip: {
        labelSmall: {
          paddingLeft: 4,
          fontSize: 10,
          paddingRight: 4,
        },
      },
    },
    props: {
      MuiTooltip: {
        arrow: true,
        style: {
          pointerEvents: "all",
        },
      },
      MuiButton: {
        disableFocusRipple: true,
      },
      MuiDivider: {
        style: {
          marginTop: 16,
          marginBottom: 16,
        },
      },
    },
    typography: {
      allVariants: {
        fontFamily: "Paytone One",
      },
      button: {
        textTransform: "capitalize",
      },
    },
    shape: {
      borderRadius: 4,
    },
    palette: {
      type: "dark",
      primary: {
        main: "#f7888c",
        contrastText: "#fff",
      },
      secondary: {
        main: "#97b9c9",
        contrastText: "#fff",
      },
      background: {
        paper: "#444",
        default: "#222",
      },
      common: {
        black: "#191919",
        white: "#FCFCFC",
      },
    },
  }),
);

export const ThemeSelectorContext = createContext({
  theme: "",
});

export const useThemeSelector = () => useContext(ThemeSelectorContext);

/* eslint-disable-next-line react/display-name */
export const withTheme = (Component) => (props) => {
  const [t, setT] = useState("light");
  const toggleTheme = () => {
    const newTheme = t === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setT(newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setT(savedTheme);
    }
  }, []);
  const theme = t === "light" ? light : light;

  return (
    <ThemeSelectorContext.Provider value={toggleTheme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    </ThemeSelectorContext.Provider>
  );
};
