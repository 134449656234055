const APIEndpoints = Object.freeze({
  PRESIGN_URL: { method: "POST", url: "/assets/pre-signed-urls" },
  USER: {
    TRANSFER_CKB: { method: "POST", url: "/users/me/transfer/ckb" },
    TRANSFER_NFT: { method: "POST", url: "/users/me/transfer/nft" },
    GET_BALANCE: ({ method: "GET", url: "/users/me/balance" }),
    BY_ID: (userId) => ({ method: "GET", url: `/users/${userId}` }),
    UPDATE: { method: "PATCH", url: "/users/me" },
    DELETE: { method: "DELETE", url: "/users/me" },
    INSTANCES: {
      ALL: { method: "GET", url: "/users/me/instances" },
      GET: (params) => {
        const urlparams = new URLSearchParams(params).toString();
        return {
          method: "GET",
          url: `/users/me/instances?${urlparams}`,
        };
      },
      BY_CATEGORY: (category) => ({
        method: "GET",
        url: `/users/me/instances?category=${category}`,
      }),
      BY_ID: (id) => ({ method: "GET", url: `/users/me/instances/${id}` }),
    },
    DECKS: {
      GET_ALL: { method: "GET", url: "/users/me/decks" },
      PLAYABLES: { method: "GET", url: "/users/me/decks/playables" },
      GET_BY_ID: (deckId) => ({
        method: "GET",
        url: `/users/me/decks/${deckId}`,
      }),
      ADD: { method: "POST", url: "/users/me/decks" },
      REMOVE: (deckId) => ({
        method: "DELETE",
        url: `/users/me/decks/${deckId}`,
      }),
      ADD_INSTANCE: (instanceId, deckId) => ({
        method: "POST",
        url: `/users/me/decks/${instanceId}/${deckId}`,
      }),
      REMOVE_INSTANCE: (instanceId, deckId) => ({
        method: "DELETE",
        url: `/users/me/decks/${instanceId}/${deckId}`,
      }),
    },
  },
  EXCHANGE_RATE: {
    GET: { method: "GET", url: "/ckb-rate" },
  },
  SESSION: {
    REFRESH: { method: "POST", url: "/sessions/tokens" },
    GET: (token) => ({ method: "get", url: `/sessions/${token}` }),
    CREATE: {
      CHALLENGE: {
        method: "POST",
        url: "/sessions/signature-challenges",
      },
      WITH_GOOGLE: {
        method: "POST",
        url: "/sessions/google",
      },
      WITH_METAMASK: {
        method: "POST",
        url: "/sessions/metamask",
      },
      WITH_FACEBOOK: {
        method: "POST",
        url: "/sessions/facebook",
      },
    },
  },
  STORE: {
    PAYMENT: {
      SEND_ID: (characterId) => ({
        method: "POST",
        url: `/users/me/${characterId}/buy`,
      }),
      CKB_INTENT: (characterId) => ({
        method: "POST",
        url: `/users/me/${characterId}/buy/ckb`,
      }),
      CKB_STATUS: (purchaseCkbId) => ({
        method: "PATCH",
        url: `/users/me/purchases/ckb/${purchaseCkbId}/status`,
      }),
      PURCHASE_STATUS: (purchaseId) => ({
        method: "PATCH",
        url: `/users/me/purchases/${purchaseId}/status`,
      }),
      PURCHASES: (purchaseId) => ({
        method: "POST",
        url: `/users/me/purchases/${purchaseId}/confirm`,
      }),
      CKB_TRANSACTION: (itemId) => ({
        method: "POST",
        url: `/users/me/${itemId}/buy/ckb-transaction`,
      }),
      CKB_TRANSACTION_HASH: (transactionId) => ({
        method: "PATCH",
        url: `/users/me/buy/ckb-transaction/${transactionId}`,
      }),
    },
    ITEMS: {
      GET_BY_ID: (id) => ({ method: "GET", url: `/items/${id}` }),
      GET: (params) => {
        const urlparams = new URLSearchParams(params).toString();
        return {
          method: "GET",
          url: `/items?${urlparams}`,
        };
      },
      PACKS: {
        GET: {
          method: "GET",
          url: "/items/packs",
        },
      },
    },
    QUOTE_TOKENS: {
      GET: { method: "GET", url: "/quote-tokens" },
    },
  },
  ITEMS: {
    CATEGORIES: {
      GET: { method: "GET", url: "/items/categories" },
    },
  },
  RANKING: {
    SEASON: {
      GET: { method: "GET", url: "/season" },
    },
    USER: {
      GET: (userId, seasonId) => ({ method: "GET", url: `/users/${userId}/${seasonId}` }),
    },
    REWARDS: {
      GET: (seasonId) => ({ method: "GET", url: `/season/${seasonId}/rewards` }),
    },
    GLOBAL: {
      GET: { method: "GET", url: "/ranking" },
    },
    GENERAL: {
      GET: { method: "GET", url: "/season/all" },
    },
    GET: (id) => ({ method: "GET", url: `/ranking/${id}` }),
  },
  PAYMENT_HISTORY: {
    GET: (params) => {
      const urlparams = new URLSearchParams(params).toString();
      return {
        method: "GET",
        url: `/users/me/buy/ckb-transaction?${urlparams}`,
      };
    },
  },
  ALL_CARDS: {
    GET: { method: "GET", url: "/metadata/all" },
  },
  GLOBALS: {},
});

export default APIEndpoints;
