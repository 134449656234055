import createSeoConfig from "./createSeoConfig";

const createDefaultSeoConfig = () => createSeoConfig({
  title: "Celebrity Smack Down",
  description: "A strategic NFT card game that puts your favorite celebrities in a battle to cancel each other.",
  images: [
    {
      url: "https://cdm-backend-assets.s3.amazonaws.com/csd-logo.jpg",
      secure_url: "https://cdm-backend-assets.s3.amazonaws.com/csd-logo.jpg",
      width: 300,
      height: 203,
      alt: "logo",
      type: "image/jpeg",
    },
  ],
  author: "Rather Labs",
  publishedTime: "2021-12-21T14:00:00Z",
  twitter: [{
    cardType: "summary",
    site: "@CSDGame",
    handle: "@CSDGame",
  }],
});

export default createDefaultSeoConfig;
