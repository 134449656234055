/* eslint-disable react/no-danger */
import Router, { useRouter } from "next/router";
import { useEffect, useRef } from "react";

import { useAppState, withAppState } from "store/AppState";
import { withBlockchain } from "store/Blockchain";
import { withSession } from "store/Session";

import TransparentLayout from "layouts/TransparentLayout";
import useLatestVersion from "hooks/useLatestVersion";

import createSeoInjector from "hocs/createSeoInjector";
import withDialogs from "hocs/withDialogs";
import withToasts from "hocs/withToasts";

import createDefaultSeoConfig from "seo/app";
import { DefaultSeo } from "next-seo";
import { withTheme } from "theme";
import NProgress from "nprogress";
import { withI18n } from "i18n";

import "nprogress/nprogress.css";
import "global.css";

// styles for carousel component
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "components/InfiniteCarousel/style.css";

import withStripe from "hocs/withStripe";
import withQuotes from "hocs/withQuotes";

NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeError", () => NProgress.done());
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => {
  NProgress.done();
  const next = document.querySelector("#__next");
  next.scrollTo(0, 0);
});

const App = ({ Component, pageProps }) => {
  const { state, setState } = useAppState();
  const didMountRef = useRef();
  const router = useRouter();
  useLatestVersion();

  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) jssStyles.parentNode.removeChild(jssStyles);
  }, []);

  useEffect(() => {
    if (didMountRef.current && !state.hasHistory) {
      setState({ hasHistory: true });
    } else {
      didMountRef.current = true;
    }
  }, [router.pathname]);

  const Layout = Component.Layout || TransparentLayout;

  return (
    <>
      <meta
        name="viewport"
        content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi"
      />
      <link rel="icon" type="image/png" sizes="16x16" href="https://cdm-backend-assets.s3.amazonaws.com/users/usr_Situ6RC5eVazg/avatars/ast_WCt3a3aLHVVHq.png" />

      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
};

const withSeo = createSeoInjector(createDefaultSeoConfig, DefaultSeo);

const hocs = [
  withQuotes,
  withStripe,
  withDialogs,
  withAppState,
  withToasts,
  withTheme,
  withI18n,
  withBlockchain,
  withSession,
  withSeo,
];

const wrappedApp = hocs.reduce((app, wrapper) => wrapper(app), App);

export default wrappedApp;
